@import "config/variables";
@import "config/bootstrap";
@import "config/datepicker";

@import "~draft-js/dist/Draft.css";
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-toggle/style.css";
@import "~antidote-medical-screening/src/styles/screening.scss";

@import "~react-datetime/css/react-datetime.css";
