
.question-panels {
  .condition-question-header {
    cursor: move;

    .btn-toolbar {
      float: right;
    }

    .btn {
      padding: 0 5px;

      color: black;
      outline: none;
    }

    .reorder {
      cursor: move;
    }
  }

  .table-responsive {
    overflow-x: visible;

    > table {
      margin-bottom: 0;
    }
  }

  > div > div {
    padding-bottom: 10px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  table > thead {
    th:first-of-type {
      width: 300px;
    }
    th:last-of-type {
      width: 400px;
    }
  }
}

