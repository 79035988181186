.changeset {
  th {
    width: 33.33%;
  }

  th:first-of-type,
  td:first-of-type {
    padding-left: 15px;
  }

  th:last-of-type,
  td:last-of-type {
    padding-right: 15px;
  }

  &.new {
    .after {
      background: rgba(141, 176, 19, 0.28);
    }
  }

  &.deleted {
    .before {
      background: rgba(176, 21, 23, 0.28);
    }
  }

  .changed {
    //font-weight: 600;

    .before {
      background: rgba(176, 21, 23, 0.28);
    }

    .after {
      background: rgba(141, 176, 19, 0.28);
    }
  }
}
