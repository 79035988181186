@import "../../../../styles/config/variables";

.header {
  background: #fff;

  .navbar {
    height: 50px;
    .nav-link.active {
      background-color: $darker-blue;
    }
  }

  .logo {
    margin: 20px 0;
    width: auto;
    height: 100px;
  }

  .logo-container {
    display: inline-block;
  }

  .session {
    color: #FFF;
    margin-top: 16px;
  }
}
