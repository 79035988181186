@import '../../../../styles/config/bootstrap';

.permissions-container {
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid $text-color;
  margin: 4px;

  .permission-readonly {
    display: flex;
    align-content: center;
    align-items: center;
    color: $brand-secondary;
    svg{
      margin-right: 3px;

    }

  }
  .permissions-title,
  .permissions-title:hover {
    padding: 5px 15px;
    font-size: 1.3em;
    color: #fff;
    background: $brand-primary !important;
    transition: none !important;
    margin-bottom: 0;
  }

  .permissions-body {
    @media (min-width: $screen-sm-min) {
      height: 300px;
      overflow: scroll;
    }
  }

  .permission-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
