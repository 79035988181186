.header {
  background: #fff;

  .navbar {
    height: 50px;
  }

  .logo {
    margin: 20px 0;
    width: auto;
    height: 100px;
  }

  .logo-container {
    display: inline-block;
  }

  .medical-library {
    float: right;
    padding-top: 30px;
  }
}
