@import '../../../styles/vendor';

.react-bootstrap-table th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
}

.react-bootstrap-table th .order-4:after {
  content: "\2193";
}

.react-bootstrap-table th .caret-4-asc:after {
  margin-left: 3.5px;
  content: "\2193";
}

.react-bootstrap-table th .caret-4-desc:after {
  margin-left: 3.5px;
  content: "\2191";
}

.react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0 !important;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none;

}

.table-hover tbody tr:hover {
  cursor: pointer;
}

.custom-data-table {
  td {
    padding: 0 !important;
    border-top: 1px solid $brand-secondary !important;
    border-bottom: 1px solid $brand-secondary !important;
  }
}
