@import "variables";

$brand-primary: $blue;
$brand-secondary: $cega-gray-light;
$brand-tertiary: $grey;

$text-color-primary: $brand-tertiary;

//** Background color for `<body>`.
$body-bg:               $cega-gray-light;
//** Global text color on `<body>`.
$text-color:            #222;

//** Global textual link color.
$link-color:            $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);

$small-font-size: .6em;

//Buttons
$button-primary-border: $brand-primary;
$button-primary: $brand-primary; //bg
$button-outline: $brand-primary;

$button-default-border: $brand-secondary;
$button-default: $brand-secondary;
$button-default-outline: $brand-secondary;

$button-secondary-background-color: $brand-primary;
$button-secondary-border: $brand-primary;
$button-secondary-outline: $brand-primary;

$btn-primary-bg: $button-primary;
$btn-primary-border: $button-primary-border;
$btn-primary-color: #ffffff;
$btn-primary-outline: $button-primary-border;

$btn-default-bg: $button-default;
$btn-default-border: $button-default-border;
$btn-default-color: $text-color-primary;
$btn-default-outline: $button-default-outline;

// Labels
$label-default-bg: $blue;

// Navbar
$navbar-dark-color: white;

// Tabs
$nav-tabs-link-active-color: white;
$nav-tabs-link-active-bg: $brand-primary;
$nav-tabs-border-width: 1px;
$nav-tabs-border-color: $brand-primary;

.input-group-text {
  background-color: $brand-primary;
  border-color: $brand-primary;
  svg {
    width: 16px;
    height: 16px;
  }
  &.alt {
    background-color: white;
    color: $brand-primary;
    border-color: white;
  }
}

.nav-tabs .nav-link {
  font-size: 14px;
}

.dropdown .dropdown-menu .nav-item .nav-link {
  color: $text-color;
  &:hover {
    color: $brand-primary;
  }
}

.btn-default,
.btn-secondary {
  color: $btn-default-color;
  background: $btn-default-bg;
  border: 1px solid lighten($btn-default-border, 5%);
  padding: 0.6em 1em;

  &:hover {
    color: $btn-default-color;
    background: darken($btn-default-bg, 10%);
    border-color: lighten($btn-default-outline, 10%);
    cursor: pointer;
  }

  &:active {
    color: $btn-default-color;

    background: $btn-default-bg;
    border: 1px solid lighten($btn-default-outline, 5%);
  }

  &:focus {
    border: 1px solid lighten($btn-default-outline, 5%);
  }
}

.btn-secondary {
  color: #FFFFFF;
  background-color: $button-secondary-background-color;
  border-color: $button-secondary-border;
  outline: $button-secondary-outline;
}

.btn-block {
  margin-bottom: 5px;
}
.btn {
  font-weight: bold;
  font-size: 1em;
  //font-family: $font-button;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  min-width: 80px;
  margin-bottom: 5px;

  text-transform: capitalize;

  &.btn-xs {
    font-size: 0.8em;
  }

  // margin-bottom: 5px;

  .btn-sm {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  &:focus {
    box-shadow: none;
  }

  :first-child {
    padding-right: 3px;
  }
}

// primary button
.btn-primary {
  fill: white;
  padding: 0.6em 1em;
}

.btn-action {
  background-color: $button-primary;
  border-color: $button-outline;
  padding: 0.6em 1em;
  &:hover {
    background: $button-primary;
    border-color: lighten($button-outline, 10%);
    cursor: pointer;
  }
  &:active {
    background: $button-primary !important;
    border: 1px solid lighten($button-outline, 5%);
  }
  &:focus {
    border: 1px solid lighten($button-outline, 5%);
  }

  ::after {
    font-size: 0.95em;
    content: ' \25B6';
  }
}

.btn-question {
  background-color: lighten($button-primary, 10%);
  padding: 1em 1.2em;
  border-color: lighten($button-primary, 10%);
  border-radius: $radius-normal;
  color: white;
  font-weight: bold;

  &.active {
    background: $button-primary !important;
    border: 1px solid $button-primary;
    box-shadow: 0 0 0 0.2rem white;
    font-weight: bold;
    color: white;
  }
  &:hover {
    background: $button-primary;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}

.dropdown .dropdown-menu .nav-item .nav-link {
  margin-bottom: 2px;
  font-size: 90%;
  &.status-pending {
    background-color: $brand-primary;
    color: white;

    &:hover {
      opacity: 0.9;
      background-color: $brand-primary;
      color: white;
    }
  }

  &.status-active {
    background-color: $brand-tertiary;
    color: white;

    &:hover {
      opacity: 0.9;
      background-color: $brand-tertiary;
      color: white;
    }
  }

  &.status-ended {
    background-color: $brand-primary;
    color: white;

    &:hover {
      opacity: 0.9;
      background-color: $brand-primary;
      color: white;
    }
  }

  &.status-test {
    background-color: $brand-primary;
    color: white;

    &:hover {
      opacity: 0.9;
      background-color: $brand-primary;
      color: white;
    }
  }
}

.btn-actions-selector {
  background-color: $button-primary;
  border-color: $button-outline;
  color: white;
  padding: 0.6em 1em;

  &.btn-xs {
    padding: 0.3em 0.5em;
  }

  &:hover {
    background: darken($button-primary, 10%);
    border-color: darken($button-outline, 10%);
    cursor: pointer;
    color: white;
  }
}

@mixin btn-version-selector {
  padding: 0.3em 0.5em;
  min-width: 45px;
  border-radius: $radius-normal;
  color: white;
  font-size: 1.2em;
  box-shadow: 0 0 0 0.2rem white;
  font-weight: bold;
}

.btn-version-selector-status-active {
  @include btn-version-selector;
  background: $green;
  border: 1px solid $green;
  &:hover {
    background: darken($green, 10%);
    border-color: darken($green, 10%);
    cursor: pointer;
    color: white;
  }
}

.btn-version-selector-status-ended {
  @include btn-version-selector;
  background: $brand-primary;
  border: 1px solid $brand-primary;
  &:hover {
    background: darken($brand-primary, 10%);
    border-color: darken($brand-primary, 10%);
    cursor: pointer;
    color: white;
  }
}

.btn-version-selector-status-pending {
  @include btn-version-selector;
  background: $brand-tertiary;
  border: 1px solid $brand-tertiary;
  &:hover {
    background: darken($brand-tertiary, 10%);
    border-color: darken($brand-tertiary, 10%);
    cursor: pointer;
    color: white;
  }
}
.btn-version-selector-status-test {
  @include btn-version-selector;
  background: $brand-tertiary;
  border: 1px solid $brand-tertiary;
  &:hover {
    background: darken($brand-tertiary, 10%);
    border-color: darken($brand-tertiary, 10%);
    cursor: pointer;
    color: white;
  }
}

.btn-version-selector-status-active {
  @include btn-version-selector;
  background-color: $green;
  border: 1px solid $green;
  &:hover {
    background: darken($green, 10%);
    border-color: darken($green, 10%);
    cursor: pointer;
    color: white;
  }
}

.btn-toggle {
  background-color: lighten($button-primary, 30%);
  padding: 0.6em 1em;
  border-color: lighten($button-primary, 30%);
  border-radius: $radius-normal;
  color: white;
  font-weight: bold;

  &.active {
    background: $button-primary !important;
    border: 1px solid $button-primary;
    box-shadow: 0 0 0 0.2rem white;
    font-weight: bold;
    color: white;
  }
  &:hover {
    background: $button-primary;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}

.btn-group-toggle {
  //height: 2.8em;
  //padding-top: 0.2em;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle):not(.btn-toggle),
.btn-group > .btn-group:not(:last-child) > .btn-question {
  border-top-right-radius: $radius-normal;
  border-bottom-right-radius: $radius-normal;
  margin-right: 6px;
  min-width: 120px;
}

.btn-group > .btn:not(:first-child):not(.btn-toggle),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: $radius-normal;
  border-bottom-left-radius: $radius-normal;
  min-width: 120px;
}

.modal-80w {
  width: 80%;
}

.badge {
  float: right;
  margin-left: 0.5em;
  padding: 8px;
  font-size: 93%;
  font-weight: 700;
  //width: 100%;
}

.tab-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: $radius-normal;
  padding: 15px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0 15px 0;
}

.icon {
  height: 16px;
  width: 16px;
}

.modal-body {
  padding: 1.5em;
}
