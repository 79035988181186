@import "bootstrap";

$cell-padding: 1em;
/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1em;

}

.DayPicker-customOverlay {
  &.pull-left {
    margin-left: -300px
  }

  position: absolute;
  z-index: 2;
  background: #FFFFFF;

}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;
  background: #FFFFFF;
  user-select: none;
  border-radius: $radius-normal;
}

.DayPicker-Months {

  display: flex;
  justify-content: center;
  flex-direction: row;;

}

//.DayPicker-Month:first-of-type{
//   margin-right: 0.1em
//}
//.DayPicker-Month:last-of-type{
//  margin-left: 0.1em
//}


.DayPicker-Month {
  display: flex;
  flex-direction: column;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;

}

.DayPicker-NavBar {

}

.DayPicker-NavButton {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  display: inline-block;
  //paddi-top: 16px;
  width: 100%;
  // height: 3em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #FFFFFF;
  cursor: pointer;
  fill: #FFFFFF;
}

.DayPicker-NavButton--next:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--next {
  float: right;
  margin-right: 5px;

}

.DayPicker-NavButton--close {
  display: flex;
  justify-content: flex-end;
  padding-right: 9px;
}

.DayPicker-NavButton-nav-row {
  justify-content: space-between;
  display: flex;
  flex: 1;
  padding-right: 4px
}

.DayPicker-NavButton--prev {
  float: left;
  margin-left: 5px
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  text-align: center;
  background-color: $brand-primary;
  color: #FFFFFF;
  padding: 2em 1em 0.2em 1em;
}


.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Caption-Selects {
  display: flex;
}


.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 1.04em;
  color: $text-color;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.4em 0.9em;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: $text-color;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.4em 0.9em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: $brand-primary;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: $brand-primary;
  font-weight: 700;
}

.DayPicker-Day--outside {
  // color: #8B9898;
  color: $text-color-primary;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: $brand-tertiary;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: $brand-primary;
  color: #FFFFFF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: darken($brand-primary, 8%);
}

.DayPicker:not(.DayPicker--interactionDisabled),
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: $brand-secondary;
}

/* DayPickerInput */

.DayPickerInput {
  display: block;
  clear: both;

  .form-control {
    width: 200px;
  }
}

.DayPickerInput-append {
  cursor: pointer;
  .input-group-text {
    color: white;
  }
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 2;


  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  color: #FFFFFF !important;
  background-color: lighten($brand-primary, 30%);
}

.Range .DayPicker-Day--endDate {
  background-color: $brand-primary !important;
  color: #FFFFFF
}

.Year .DayPicker-Day {
  padding: 0.4em 0 0.4em 0;
  width: 47px;
}

.Year .DayPicker-Weekday {
  padding: 0.4em 0 0.4em 0;
  width: 47px;
}

.TimePicker {
  position: absolute;
  z-index: 2;
  border: 1px solid $brand-secondary;
  border-radius: $radius-normal;
}

.TimePicker .react-timekeeper__top-bar {
  background-color: $brand-secondary;
}

.TimePicker .react-timekeeper__tb-hour {
  color: $text-color;
}

.TimePicker .react-timekeeper__tb-colon {
  color: $text-color;
}

.TimePicker .react-timekeeper__tb-minute {
  color: $text-color;
}

.TimePicker .react-timekeeper__clock-wrapper {
  background-color: #FFFFFF;

}

.TimePicker .react-timekeeper__clock {
  background-color: $brand-tertiary;
  border: 1px solid $brand-secondary;
}

.TimePicker .react-timekeeper__done-button {
  background-color: $brand-primary;
  color: white;
  font-weight: bold;
}

@mixin hand {
  fill: $brand-tertiary;
  stroke: $brand-tertiary;
  color: white;
}

.TimePicker .react-timekeeper__hand-circle-outer {
  @include hand;
}

.TimePicker .react-timekeeper__hand-intermediate-circle {
  @include hand;
}

.TimePicker .react-timekeeper__hand-circle-outer {
  @include hand;
}

.TimePicker .react-timekeeper__clock-hand {
  @include hand;

}

.TimePicker .react-timekeeper__clock-hours span {
  color: $brand-secondary;
}

.TimePicker .react-timekeeper__clock-minutes span {
  color: $brand-secondary;
}

.TimePicker .react-timekeeper__dropdown-numbers li {
  color: $text-color;

  &:hover {
    background-color: $brand-secondary;
    color: #FFFFFF;
  }
}
