@import '../../../../styles/config/variables';

.login-box-container {
  padding: 15vh 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .spacer {
    height: 1px;
  }

  .login-box {
    width: 100%;
    //margin: auto;
    margin-left: 15px;
    margin-right: 15px;

    > .box {
      padding: 45px;
      margin-right: -5px;
      margin-top: 2px;
      margin-bottom: 2px;

      fill: white;
    }

    @media (min-width: $screen-xs-min) {
      width: 500px;
    }

    .invalid-error {
      background: #fff;
      //border-radius: 0 0 20px 20px;
      border-radius: $radius-normal;
      padding: 15px;
      text-align: center;
    }

  }

  .notification-row {
    margin-top: 20px;
  }
}
