@import '../../../styles/config/bootstrap';

.check-circle {
  input {
    display: none;
  }

  label {
    margin-right: 20px;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    display: block;
    clear: both;

    &.inline {
      display: inline-block;
    }

    &.no-margin {
      margin-right: 0;
    }
  }

  .label__check {
    border-radius: 50%;
    border: 2px solid $brand-tertiary;
    background: white;
    vertical-align: middle;
    margin-right: 20px;
    width: 2.5em;
    height: 2.5em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .3s ease;

    &.no-margin {
      margin-right: 0;
    }

    &.small {
      margin-right: 10px;
    }

    &.small {
      border: 1px solid $brand-tertiary;
    }
    &.small:hover {
      border: 2px solid rgba($brand-tertiary, 0.7);
    }

    &:hover {
      border: 2px solid rgba($brand-tertiary, 0.7);
    }
  }

  .label__text {
    display: flex;
  }

  input:checked + .label__text .label__check {
    border: 2px solid $brand-tertiary;
    background: $brand-tertiary;

    svg {
      opacity: 1;
      fill: white;
    }
  }

  input:not(:checked) + .label__text .label__check {
    svg {
      opacity: 0;
    }
  }
}
