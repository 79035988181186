@import "config/bootstrap";

html, body {
  margin: 0;
}

main {
  .header-block h1 {
    margin-top: 10px;
  }

  .content-block {
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.2);
    border-radius: 3px;
    padding: 15px 20px;
    margin: 15px 0;

    @media (max-width: $screen-lg-min) {
      margin: 15px 0;
    }

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    h2 {
      margin-top: 10px;
    }
  }
}

.page-header {
  margin-top: 5px;

  h1 {
    margin-top: 0;
  }
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1, h2, h3, h4, h5, h6, th {
  color: $blue;
  font-weight: 200;
  letter-spacing: .07em;
}
h1 {
  font-size: 1.8em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.2em;
}
h4 {
  font-size: 1.2em;
}

.form-horizontal .control-label {
  text-align: left;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: $grey;
}

.pull-right {
  float: right;
}

.top-margin {
  margin-top: 15px;
}
.bottom-margin {
  margin-bottom: 15px;
}

.page-wrapper {
  min-height: 100vh;
  margin: 0 auto -43px; /* the bottom margin is the negative value of the footer's height */
}

.footer, .push {
  clear: both;
  min-height: 63px; /* '.push' must be the same height as 'footer' */
  .container {
    margin-bottom: 0;
  }
}

.current-user {
  margin-top: 30px;
  text-align: right;
}

.screening-view {
  h2 {
    margin-top: 0;
  }
}

.white {
  color: white;
}

.unmarked-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dnd-item-container {
  .dnd-item-icon {
    text-align: right;
  }
}

.remove-btn {
  border: 1px solid $brand-primary;
  color: $brand-primary;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 15px;
  max-height: 40px;
  border-radius: $radius-normal;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 1px 4px rgba(0,0,0,0.3);
  }
}

.removable-item {
  display: flex;

  .item-section {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .remove-section {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    > div:last-child, > a:last-child {
      margin-left: 15px;
    }
  }
}

.subtitle {
  font-size: 80%;
  color: lighten($text-color, 50%);
}
