@import '../../../styles/vendor';

.loading__container {
  position: relative;
  width: 100%;
  min-height: 500px;

  &.small {
    min-height: 200px;
  }

  &.message-only {
    min-height: 150px;
  }
}

.loading__center-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.loading__circle {
  width: 10rem;
  height: 10rem;
  border: 4px solid $brand-secondary;
  border-top-color: transparent;
  border-radius: 100%;
  display: block;
  animation: rotate 1.5s linear infinite;
}

.loading__message {
  font-size: 1.2em;
  padding: 30px;

  .loader-dots {
    font-size: 14px;
    margin-top: 30px;
  }

  text-align: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader-dots {
  svg {
    animation: loader 1.2s infinite ease-in-out;
    margin: 2px;
  }
  svg:nth-child(2) {
    animation-delay: .6s;

  }

  svg:nth-child(3) {
    animation-delay: .3s;
  }

}

@keyframes loader {
  0% {
    opacity: .4;

  }

  50% {
    opacity: 1;

  }

  100% {
    opacity: .4;
  }
}
