@import "../../../../styles/config/variables";

.footer {
  .container {
    padding-top: 20px;
  }

  .navigation {
    list-style: none;
    float: right;
    li {
      display: inline;

      a {
        padding: 0 10px;
        border-right: 1px solid #fff;
      }

      &:last-of-type a {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  background: $blue;
  color: #fff;

  a, a:visited, a:hover {
    color: #fff;
  }

  .footer-text {
    max-height:42px;
    margin-top:-12px;

    p {
      padding:0px;
      margin-top:2px;
      margin-bottom:2px;
    }
  }
}
