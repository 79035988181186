.button-bar {
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 5px;
  clear: both;
  display: block;
  overflow: visible;
  min-height: 40px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    // margin-left: 0.5em;
    &:first-child {
      margin-left: 0;
    }
  }

  .btn {
    &.pull-right {
      float: right;
      margin-left: 0.5em;
    }
    &.pull-left {
      float: left;
      margin-right: 0.5em;
    }
    //margin-bottom: 0 !important;

    @media (max-width: 500px) {
      width: 100%;
    }

    &:first-child {
      @media (max-width: 500px) {
        width: 100% !important;
      }
    }

    &.pull-right {
      margin-left: 0.5em;
      margin-right: 0;
      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.button-bar-inside {
  margin-top: 10px;
  height: 40px;
}
