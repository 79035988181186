$green: #bed650;
$cega-gray-light: #F6F6F6;
$blue: #26325a;
$grey: #5b5b5b;
$darker-blue: #1c2543;

$text-color-header: $blue;

$radius-normal: 3px;

$no-border: 0px solid #fff;

// Responsive Variables
$screen-xs-min: 480px; // ~Phone Size
$screen-sm-min: 768px; // ~Phablet Size
$screen-md-min: 992px; // ~Tablet Size
$screen-lg-min: 1200px; // ~Desktop Size
$screen-lg-01: 1300px; // ~Projector
